import router from './router'
import { userAdminInfo } from '@/store/modules/admin.js'

// 白名单 （游客  未付费的企业用户）
/**
 * type 0未付费企业  1个人    2企业
 */

// 白名单
const whiteList = [
  'home',
  // 'Information',
  'Membershipscheme',
  'rankinglist',
  'blank',
  'News',
  'GlobalMediaCoverage',
  'council',
  'basicsInfo',
  'searchPage',
  'smallNavbar',
  'termsofService',
  'services',
  
  //入境上海===
  'visitShanghai',
  'zxrjzc',
  'rjscdc',
  'yzrjxl',
  //======
]
// 登录后白名单
const user = [
  'orderForm',
  'learningMaterials',
  'settings',
  'data',
  'expert',
  'paymentOrder',
  'basicsInfo',
  'enterpriseMessage',
  'moreInfo',
  'certificate',
  'product',
  'moreData',
  'hotelcenter',
  'chineseOutboundPartners',
  'Insurance',
  'arbitration',
  'capital',
  'Market',
  'cooperative',
  'corporate',
  'roomdetails', // 得看自己的产品
  'Informationmore' // 得看自己的更多资料
]
// 个人权限
const personalList = [
  'data',
  'expert',
  'examinationpage',
  'learningMaterials',
  'usercenter',
  'usercenterInfo',
  'settings'
]

router.beforeEach((to, from, next) => {
  const adminInfo = userAdminInfo()

  // 在 smallNavbar 上切换语言后, 返回上一页面, 这里刷新一下, 重置页面语言
  if (localStorage.getItem('goHistory') !== null) {
    localStorage.removeItem('goHistory')
    window.location.reload()
    return
  }

  // 切换语言后, 会 reload 当前页面, 这里重新设置一下 title
  document.title =
    adminInfo.language === 'cn' ? '文旅跨境服务平台' : 'SmoothTravel Official Travel Platform'

  if (!adminInfo.token) {
    if (whiteList.includes(to.name)) {
      next()
    } else {
      // 兼容所在位置是白名单的情况下, 不跳首页
      if (!whiteList.includes(from.name)) {
        router.push('/home')
      }

      adminInfo.setPermission(true)
    }
  } else if ((adminInfo.isEnterprise() || adminInfo.isAssociation()) && adminInfo.unPaid()) {
    const permission = [...whiteList, ...user]
    if (permission.includes(to.name)) {
      next()
    } else {
      adminInfo.setPermission(true)
    }
  } else if (adminInfo.isPersonal()) {
    const permission = [...whiteList, ...user, ...personalList]
    if (adminInfo.personalHasAuth()) {
      next()
    } else if (permission.includes(to.name)) {
      next()
    } else {
      adminInfo.setPermission(true)
      next()
    }
  } else {
    next()
  }
})
